<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的余额
        </div>
        <div class="vip-content-inner">
             <div class="balance">
                 <h3>余额</h3>
                 <p>
                     <em>{{ moneyTotal }}</em>
                     元
                 </p>
             </div>
            <div class="balance-list">
                <div class="title">余额明细</div>
                <div class="item" v-for="(item,index) in recordpPage" :key="index">
                    <div class="text">
                        <p>{{ item.remarks }}</p>
                        <div class="date">{{ item.createTime }}</div>
                    </div>
                    <div class="num">
                        <div :class="item.type==0?'orange':'green'">{{item.type==0?'+':'-'}}{{ item.money }}元</div>
                    </div>
                </div>
                <div class="mat20 text_cen" v-if="recordpPage.length==0">暂无数据</div>
            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="pageSizes"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {moneyRecordpPage} from "@/api/transaction";
import {userDetail} from "@/api/account";

export default {
    name: "balance",
    data() {
        return {
            moneyTotal:0, // 账户余额
            recordpPage:[],

            current:1, // 页数
            size:10, // 每页条数
            pageSizes:[10, 20, 50],
            total:0, // 数据总数
        }
    },
    mounted() {
        this.getmoneyRecordpPage();
        this.getuserDetail() // 获取用户信息
    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getmoneyRecordpPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getmoneyRecordpPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getmoneyRecordpPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getmoneyRecordpPage();
        },

        // -------------接口请求
        getmoneyRecordpPage(){
            let param={
                current:this.current,
                size:this.size,
                customerId:localStorage.getItem('customId'),
            }
            moneyRecordpPage(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('余额记录',res.data.data)
                    this.recordpPage=res.data.data.records
                    this.total=Number(res.data.data.total)

                }
            }).catch(err=>{
            })
        },


        getuserDetail(){ // 获取用户信息
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId:userId}).then((res) => {
                if (res.data.code==200) {
                    localStorage.setItem('customInfo', JSON.stringify(res.data.data)); // 登录用户信息
                    localStorage.setItem('customId', res.data.data.id)
                    this.moneyTotal=JSON.parse(localStorage.getItem('customInfo')).moneyTotal || 0


                }

            })
        },
    },
}
</script>

<style scoped>

</style>
